import {useMemo} from 'react';
import moment from "moment-timezone";

export function useHandleChange(setState,state){
      const ret=useMemo(()=>{
            return e=>setState({...state,[e.target.name]: e.target.value });
      },[setState,state]);
      return ret;
}

export function getReactRoutedUrlParams(){
  return new URLSearchParams(window.location.hash.replace("#","").replace(/\/.*\?/,""));
}

export function shiftPickerDateToTimezoneDate(pickerDate, timezone){
  let pickerOffset = moment(pickerDate).utcOffset();
  let utcDate = new Date();
  utcDate.setTime(moment(pickerDate).valueOf() + pickerOffset * 60000);

  let tzOffset = moment.tz(pickerDate, timezone).utcOffset();
  let tzDate = new Date();
  tzDate.setTime(utcDate.getTime() - tzOffset * 60000);

  return tzDate;
};
/*
 const shiftTimezoneDateToPickerDate = (tzDate, timezone) => {
  let tzUtcOffset = moment.tz(tzDate, timezone).utcOffset();
  let utcDate = new Date();
  utcDate.setTime(moment(tzDate).valueOf() + tzUtcOffset * 60000);

  let pickerDate = new Date();
  let pickerOffset = pickerDate.getTimezoneOffset();
  pickerDate.setTime(utcDate.getTime() + pickerOffset * 60000);

  return pickerDate;
};*/

export function translateUtcDates(body){
  //improve to nested properties
  let obj=JSON.parse(body);
  for (var key in obj) {
      // skip loop if the property is from prototype
      if (!obj.hasOwnProperty(key)) continue;
      var prop = obj[key];
      if(prop instanceof Date){
        obj[key]=shiftPickerDateToTimezoneDate(prop,'UTC');
      }else if(key.toLowerCase().indexOf("date") !== -1){
        if(prop&&prop!=="")
          obj[key]=shiftPickerDateToTimezoneDate(Date.parse(prop),'UTC').toISOString();
      }
  }
  return JSON.stringify(obj);
}

export function isDevEnv(){
  return process.env.NODE_ENV==='development'||window.location.hostname === "localhost";
}

/**
 * Generates a currency symbol string for a given currency code.
 *
 * @param {string} currency - The currency code (e.g., 'USD', 'EUR', 'GBP').
 * @returns {string} The currency symbol associated with the given currency code.
 */
export function symbolFromCurrency(currency) {
  return `${Number()
    .toLocaleString("en", { style: "currency", currency: currency })
    .replace(/\d+([,.]\d+)?/g, "")}`;
}

/**
 * Formats a currency amount with abbreviation using a currency symbol.
 *
 * @param {Object} props - The options object with 'amount' and 'currency' properties.
 * @returns {string|null} The formatted currency string with abbreviation and symbol,
 *                        or null if the provided amount is invalid or the 'props' object is undefined.
 */
export function formatMoneyWithAbbreviation(props) {
  // Check if 'props' is undefined or 'amount' is not a valid number
  if (!props || typeof props.amount !== 'number' || isNaN(props.amount)) {
    return null;
  }

  const ssu = require("short-scale-units");
  const absAmount = Math.abs(props.amount);

  // Abbreviate only for numbers in the thousands or higher.
  const shouldAbbreviate = ssu.getUnitPower(absAmount) <= 2;
  const abbreviatedNumber = shouldAbbreviate ? absAmount : ssu.trimNumber(absAmount);
  const abbreviatedName = shouldAbbreviate ? "" : ssu.trimName(ssu.unitNameFromNumber(absAmount));


  return `${symbolFromCurrency(props.currency)}${abbreviatedNumber}${abbreviatedName}`;
}



/**
 * Creates a throttled function that limits the execution of the original function to a specified time interval.
 * This is useful for optimizing performance and managing resource consumption in scenarios such as UI event handling, API rate limiting, and heavy computational tasks.
 * The appropriate limit value depends on the use case:
 * - For UI responsiveness, 100-250ms is common.
 * - For API rate limiting, align with the API's rate limit.
 * - For performance optimization, 500-1000ms might be necessary, adjusted based on testing.
 * Experimentation and testing are recommended to find the optimal limit.
 *
 * @param {Function} func - The function to be throttled.
 * @param {number} limit - The time interval (in milliseconds) that determines how often the throttled function can be executed. Consider use case specifics for setting an appropriate value.
 * @returns {Function} - The throttled function.
 */
export function throttle(func, limit) {
  let isThrottled = false;

  /**
   * Throttled function that wraps the original function and limits its execution to the specified time interval.
   *
   * @returns {void}
   */
  return function() {
    if (!isThrottled) {
      const context = this;
      const args = arguments;

      isThrottled = true;
      func.apply(context, args);

      setTimeout(() => {
        isThrottled = false;
      }, limit);
    }
  };
}

/**
 * Configuration object for single selection mode in the Bryntum grid
 *
 */
export const singleSelection = {
  row: true,
  cell: false,
  rowCheckboxSelection: false,
  multiSelect: false,
  checkbox: false,
  showCheckAll: false,
  deselectFilteredOutRecords: false,
};




/**
 * Recursively converts MySQL datetime strings to ISO 8601 format in all objects.
 * 
 * @param {Object|Object[]} data - The object or array of objects to be processed.
 * @returns {Object|Object[]} The processed data with date strings converted to ISO 8601 format.
 */
export function convertMySQLDatesInData(data) {
    /**
     * Checks if a value is an object.
     * 
     * @param {any} value - The value to be checked.
     * @returns {boolean} True if the value is an object, false otherwise.
     */
    function isObject(value) {
        return value && typeof value === 'object' && !Array.isArray(value);
    }

    /**
     * Checks if a string is in the MySQL datetime format.
     * 
     * @param {string} value - The string to be tested.
     * @returns {boolean} True if the string is in MySQL datetime format, false otherwise.
     */
    function isMySQLDateTime(value) {
        return (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,6})?$/.test(value));
    }

    /**
     * Converts a MySQL datetime format string to a JavaScript ISO string.
     * 
     * @param {string} dateTimeString - The MySQL datetime string to be converted.
     * @returns {string} The ISO 8601 formatted datetime string.
     */
    function convertToISOFormat(dateTimeString) {
        return new Date(dateTimeString);
    }

    if (Array.isArray(data)) {
        return data.map(item => convertMySQLDatesInData(item));
    } else if (isObject(data)) {
        Object.keys(data).forEach(key => {
            if (isObject(data[key])) {
                data[key] = convertMySQLDatesInData(data[key]);
            } else if (isMySQLDateTime(data[key])) {
                data[key] = convertToISOFormat(data[key]);
            }
        });
        return data;
    }

    // Return the data unchanged if it's neither an object nor an array.
    return data;
}


