import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";

import './index.css';
import AuthProvider from './services/AuthProvider';
import history from "./utils/history";
import * as serviceWorker from './serviceWorker';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLocation } from 'react-router';

const App = React.lazy(()=>import('./App'));
const AppNoAuth = React.lazy(()=>import('./AppNoAuth'));

function NoAuthCheckWrapper(){
  const location = useLocation();
  const noauth=location.pathname.includes('noauth')||location.pathname==='/';
  console.log("noauth path:"+noauth);
  return (
    <>
      {!noauth &&
        <AuthProvider>
          <Suspense fallback={
              <div style={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <CircularProgress style={{ color: "#5897ce" }}/>
              </div>
              }>
                <App />            
          </Suspense>
        </AuthProvider> 
      }
      {noauth &&
          <Suspense fallback={
              <div style={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <CircularProgress style={{ color: "#5897ce" }}/>
              </div>
              }>
                <AppNoAuth />            
          </Suspense>
      }     
    </>

  );
};
ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <NoAuthCheckWrapper></NoAuthCheckWrapper>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
