import { PublicClientApplication,LogLevel} from "@azure/msal-browser";
import {b2cConf,b2cAuthMetaData}  from "./b2cConf";
const msalConfig={
    auth: {
      clientId: b2cConf.clientId,
      authority: b2cConf.baseUrl+"B2C_1A_UNTAP_SIGNIN",
      postLogoutRedirectUri:"/loggedout.html",
      knownAuthorities: [b2cConf.domain],
      authorityMetadata: JSON.stringify(b2cAuthMetaData)
    },
    system: {
      allowRedirectInIframe: true,//funds is rendered in an exm-untap iframe
      navigateFrameWait: 500,
      iframeHashTimeout: 7000,
      loggerOptions: {
          logLevel: LogLevel.Error,
          loggerCallback: (level, message, containsPii) => {
              if (containsPii) {
                  return;
              }
              switch (level) {
                  case LogLevel.Error:
                      console.error(message);
                      return;
                  case LogLevel.Info:
                      console.info(message);
                      return;
                  case LogLevel.Verbose:
                      console.debug(message);
                      return;
                  case LogLevel.Warning:
                      console.warn(message);
                      return;
                  default:
                      console.info(message);
                      return;
              }
          },
          piiLoggingEnabled: false
      },
    }  
  }
  const msalInstance = new PublicClientApplication(msalConfig);
  export default msalInstance;