import React, {useContext,useState,useEffect,useMemo} from 'react';
import {Link} from 'react-router-dom'

import { useLocation } from 'react-router';
import {getReactRoutedUrlParams,isDevEnv} from "../../utils/functions";
import jwt_decode from 'jwt-decode';
import CircularProgress from '@material-ui/core/CircularProgress';

import { enGB,es } from 'date-fns/locale'

import { MsalProvider,useMsalAuthentication,useIsAuthenticated } from "@azure/msal-react";
import { InteractionRequiredAuthError} from "@azure/msal-browser";
import msalInstance  from "./msalConf";
import { b2cConf }  from "./b2cConf";
import { useMsal } from "@azure/msal-react";

import { format } from "date-fns";

function getUrlContext(overrideState){
    let params=getReactRoutedUrlParams();
    let tenantId=params.get("tenantId");
    let locale=params.get("locale");
    let idps=params.get("idps");
    if(isDevEnv()&&!tenantId&&!locale){
        tenantId=process.env.REACT_APP_EXM_DEVMODE_TENANT;
        locale=process.env.REACT_APP_EXM_DEVMODE_LOCALE;
    }
    return {
            contextParamsProvided:tenantId!==null&&locale!==null,
            path:window.location.pathname,
            search:window.location.search,
            tenantId:tenantId,
            idps:idps,
            locale:locale,
            ...overrideState,
     };
}

function getUserStateFromToken(token){
  var decoded =jwt_decode(token);
  return {
      name: decoded['name'],
      email: decoded['email'],
      id: decoded['untapUserId'],
      roles: decoded['roles']?decoded['roles']:[],
      authorizedTenantId: decoded['authorizedTenantId']
  };
}
function getTokenFromMsalClient(msalClient){
    return new Promise((resolve,reject) => {
      const ret={ token:null,
        authenticated:false,
        authenticateError:false,
        decodedToken:{}};
      const tokenRequest={scopes:[b2cConf.clientId],
                          account:msalInstance.getAllAccounts()[0],
                          extraQueryParameters: {isRbacRequest:'true'}
                          };  
             if(!isDevEnv()){
                // no prompt when funds is opened in an iframe
                tokenRequest.prompt="none";
             }             
      msalInstance.acquireTokenSilent(
        tokenRequest).then(tokenResponse => {
          ret.token=tokenResponse.accessToken;
          resolve(ret);
      }).catch(error => {
        if (error instanceof InteractionRequiredAuthError) {
            return msalInstance.acquireTokenRedirect({
              account: msalInstance.getAllAccounts()[0],
              tokenRequest
            });
        }
        console.log(error);
        throw error;
      });
    });
}

export const AppContext = React.createContext();
export const useAppContext = () => useContext(AppContext);

function AuthWrapper(props) {
  const urlState=props.urlState;
  const [userState,setUser] = useState(null);
  const { instance, inProgress } = useMsal();
  const locales = useMemo(()=>({en:enGB,es:es}),[]);
  const appContext = useMemo(() =>{return{user:{...userState},
                                          tenantId:urlState?urlState.tenantId:null,
                                          locale:urlState?urlState.locale:null,
                                          idps:urlState?urlState.idps:null,
                                          apiBase:b2cConf.untapApiBaseUrl,
                                          getRequestHeaders:async ()=>{return {
                                            'tenantId': urlState.tenantId,
                                            'Content-Type'  : `application/json`,
                                            'Accept': 'application/json',
                                            'Accept-Language':urlState.locale,
                                            Authorization:'Bearer '+ (await getTokenFromMsalClient(props.msalInstance)).token
                                          }},
                                          isUserInRole:(role)=>{
                                            let roles=role.split(",");
                                            return userState.roles.filter(e=>roles.indexOf(e)!==-1?true:false).length>0;
                                          },
                                          formatDate (date) {
                                            return format(date,"dd/MMM/yyyy", {
                                              locale: urlState&&urlState.locale? locales[urlState.locale]:null
                                            })
                                          }
                                        }},
                     [props.msalInstance,userState,urlState,locales]);

  const loginRequest={scopes:[b2cConf.clientId],
                      account: msalInstance.getAllAccounts()[0],
                      extraQueryParameters: {isRbacRequest:'true'},
                      };
                      if(appContext!=null&&appContext.idps){
                        loginRequest.extraQueryParameters={idps:appContext.idps,...loginRequest.extraQueryParameters}
                      }
                      if(!isDevEnv()){
                        // no prompt when funds is opened in an iframe
                        loginRequest.prompt="none";
                     }
  const { error } = useMsalAuthentication("redirect",loginRequest);
  const isAuthenticated = useIsAuthenticated();

  const handleLogin = () => {
        
        instance.loginRedirect(loginRequest).catch(e => {
            console.log(e);
        });
  }
  const handleLogout = () => {
    instance.logoutRedirect({});
  };  
  //handle 
  useEffect(() => {
    const callbackId = instance.addEventCallback((message) => {
        if (message.eventType === "msal:handleRedirectEnd") {
          console.log("a redirect event was recieved");
          
        }
    });
    return () => {
        if (callbackId) {
            instance.removeEventCallback(callbackId);
        }
    }    
    });
  useEffect(() => {
      if(isAuthenticated){
          getTokenFromMsalClient(props.msalInstance).then((tokenResp)=>{
            setUser(getUserStateFromToken(tokenResp.token));
        });
      }
    },[isAuthenticated,props.msalInstance]);
  return (
         <>
          {isDevEnv() &&
             <div id="secDevBar">
                  {!isAuthenticated && inProgress==="none" && urlState.contextParamsProvided &&
                     <button onClick={()=>handleLogin()}>
                         Login
                     </button>
                   }
                   {isAuthenticated && inProgress==="none" && userState && urlState.contextParamsProvided &&
                      <div>
                         <p>User:{userState.name} <Link to="/">Home</Link></p>
                         <button onClick={()=>handleLogout()}>
                             Logout
                         </button>
                       </div>
                   }
             </div>
           }
           
           <div id="mainSecContainer">
              { error && !error.errorMessage.includes("AADB2C90077") &&
                <p>Unexpected error authenticating {error.errorMessage}</p>
              }
              { error && error.errorMessage.includes("AADB2C90077") &&
                <p>Session expired</p>
              }
              {!isAuthenticated && inProgress==="none" &&
                <p>Not Authenticated</p>
              }
              {urlState&&!urlState.contextParamsProvided && inProgress==="none" &&
                <p>Initial app context parameters not provided</p>
              }  
              {isAuthenticated  && userState  && 
                   <AppContext.Provider value={appContext}>
                        {props.children}
                   </AppContext.Provider>
              }

              {!isAuthenticated && (inProgress==="acquireToken" || inProgress==="handleRedirect"
                                 ||inProgress==="login" )&&
                <div style={{
                        width: '100%',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                  <CircularProgress style={{ color: "#5897ce" }}/>
                </div>
            }              
            </div>                        
        </>
  );
}
function AuthProvider(props) {
  const [urlState,setUrlState] = useState(null);
  let location = useLocation();
  useEffect(() => {
    let ctx=getUrlContext();
    const isAuthRedirect=window.location.hash.includes('state=');
    const hasUrlParams=window.location.hash.includes('tenantId');
    console.log("redirection:"+isAuthRedirect);
    console.log("hasUrlParams:"+hasUrlParams);
    if((!urlState&&!isAuthRedirect&&hasUrlParams)||(isDevEnv()&&!isAuthRedirect&&!urlState)){
      setUrlState(ctx);
    }
  }, [urlState,location]);
  return (
    <>
      <MsalProvider instance={msalInstance}>
          {urlState  &&
              <AuthWrapper msalInstance={msalInstance} urlState={urlState}> 
                {props.children}
              </AuthWrapper>  
          }
      </ MsalProvider>
      
    </>
  )
}
export default AuthProvider;
